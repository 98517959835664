<template>
  <v-main align="center">
    <v-container class="fill-height" fluid>
      <v-row align="start" justify="center">
        <v-col cols="12" sm="12" md="5">
          <v-card class="elevation-12" :loading="status.loading">
            <TagDetailToolbar :title="$t('tag.details')" />
            <v-form
              :lazy-validation="false"
              @submit.prevent="handleSubmit"
              v-model="valid"
              ref="form"
            >
              <v-list
                three-line
                subheader
                v-if="item != null && Object.keys(item).length > 0"
              >
                <v-list-item>
                  <v-list-item-content>
                    <device-type-select
                      v-model="item.nodeType"
                      :compact="true"
                    ></device-type-select>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field
                      :label="$t('tag.fields.deveui')"
                      name="deveui"
                      prepend-icon="mdi-domain"
                      type="text"
                      v-model="item.deveui"
                      :rules="deveuiRules"
                      disabled
                    ></v-text-field>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <application-selector
                    v-model="item.application.applicationId"
                    :advanced="false"
                  ></application-selector>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-text-field
                      :label="$t('tag.fields.name')"
                      prepend-icon="mdi-domain"
                      type="text"
                      v-model="item.name"
                      :rules="nameRules"
                    />
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="item.nodeType == 0">
                  <v-list-item-content>
                    <v-text-field
                      :label="$t('tag.fields.appKey')"
                      prepend-icon="mdi-domain"
                      type="text"
                      v-model="item.appKey"
                    />
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="item.nodeType == 0">
                  <v-list-item-content>
                    <v-text-field
                      :label="$t('tag.fields.appEui')"
                      prepend-icon="mdi-domain"
                      type="text"
                      v-model="item.appEui"
                    />
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="item.nodeType == 0">
                  <DecoderSelect
                    v-on:update-value="updateDecoder"
                    :decoder="item.decoder"
                  />
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-file-input
                      :label="$t('tag.fields.image')"
                      v-model="image"
                    />
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="item.installationImage">
                  <v-list-item-content>
                    <v-img
                      max-width="700"
                      v-if="
                        item.installationImage && SO_API_BASE_URL != undefined
                      "
                      :src="
                        SO_API_BASE_URL + '/image/' + item.installationImage
                      "
                    />
                    <v-btn color="error" type="btn" @click="removeImage">
                      {{ $t("tag.removeImage") }}
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-switch
                    name="classC"
                    prepend-icon="mdi-alpha-c-circle-outline"
                    v-model="item.classC"
                    label="Class C Device"
                  ></v-switch>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-btn
                      color="primary"
                      type="submit"
                      :disabeld="!valid"
                      x-large
                      >{{ $t("common.update") }}</v-btn
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-form>
            <v-divider></v-divider>
          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="5" class="fill-height">
          <tag-detail-stats
            v-if="Object.keys(item).length > 0"
            :deveui="item.deveui"
          />
        </v-col>

        <v-col cols="10" v-if="mqttMeta">
          <v-card>
            <v-card-text>
              <broker-selector
                v-if="mqttMeta != null"
                v-model="mqttMeta.brokerId"
              />
              <mqtt-meta-form
                v-model="mqttMeta"
                :broker="mqttMeta.brokerId"
                :deveui="item.deveui"
                :node-type="item.nodeType"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="updateMeta" color="primary">{{
                $t("common.save")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="10">
          <DownlinkEditor
            v-if="item.deveui"
            :load-tag="false"
            :deveui="item.deveui"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TagDetailToolbar from "@/components/tag/TagDetailToolbar";
import DecoderSelect from "@/components/tag/DecoderSelect.vue";
import DownlinkEditor from "@/components/tag/DownlinkEditor.vue";
import TagDetailStats from "@/components/tag/TagDetailStats";
import ApplicationSelector from "@/components/application/ApplicationSelector";
import DeviceTypeSelect from "@/components/tag/DeviceTypeSelect";
import MqttMetaForm from "../components/mqtt/MqttMetaForm.vue";
import BrokerSelector from "../components/mqtt/BrokerSelector.vue";

export default {
  name: "TagDetail",

  data() {
    return {
      valid: false,
      item: {},
      image: null,
      mqttMeta: null,
      nameRules: [
        (v) =>
          !!v || this.$t("validation.required", [this.$t("tag.fields.name")]),
        (v) =>
          (v && v.length <= 255) ||
          this.$t("validation.max", [this.$t("tag.fields.name"), 255]),
      ],

      appKeyRules: [
        (v) =>
          (v !== undefined && v.length <= 255) ||
          this.$t("validation.max", [this.$t("tag.fields.appKey"), 255]),
      ],

      deveuiRules: [
        (v) =>
          !!v || this.$t("validation.required", [this.$t("tag.fields.deveui")]),
        (v) =>
          (v && v.length <= 255) ||
          this.$t("validation.max", [this.$t("tag.fields.deveui"), 255]),
      ],
      mqttMetaCreate: false,
    };
  },

  components: {
    TagDetailToolbar,
    DecoderSelect,
    DownlinkEditor,
    TagDetailStats,
    ApplicationSelector,
    DeviceTypeSelect,
    MqttMetaForm,
    BrokerSelector,
  },

  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL"]),
    ...mapState("tag", { tags: "tags", status: "status" }),
    ...mapState("tagData", { tagDataStatus: "status" }),
    ...mapState("companies", ["status"]),
  },

  methods: {
    ...mapActions("tag", [
      "updateTag",
      "getTags",
      "getKeysForTag",
      "uploadTagImage",
      "deleteImage",
    ]),

    ...mapActions("tagData", ["clearTagData"]),
    ...mapActions("configuration", ["getBaseUrl"]),
    ...mapActions("mqtt", ["updateMqttMeta", "createMqttMeta"]),

    async updateMeta() {
      if (!this.mqttMetaCreate)
        await this.updateMqttMeta({
          mqttMetaId: this.mqttMeta.mqttMetaId,
          brokerId: this.mqttMeta.brokerId,
          payload: this.mqttMeta,
        });
      else
        await this.createMqttMeta({
          brokerId: this.mqttMeta.brokerId,
          deveui: this.item.deveui,
          payload: this.mqttMeta,
        });
    },

    removeImage() {
      let deveui = this.item.deveui;

      if (!deveui) {
        return;
      }

      this.deleteImage({ tagId: deveui });
    },

    updateDecoder(val) {
      this.item.decoder = val;
    },

    async handleSubmit() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }

      if (typeof this.item.longitude == typeof "") {
        this.item.longitude = this.item.longitude.replace(",", ".");
      }

      if (typeof this.item.latitude == typeof "") {
        this.item.latitude = this.item.latitude.replace(",", ".");
      }

      let applicationId =
        typeof this.item.application === "object"
          ? (applicationId = this.item.application.applicationId)
          : (applicationId = this.item.application);

      let payload = {
        appEui: this.item.appEui,
        appKey: this.item.appKey,
        applicationId: applicationId,
        decoder: this.item.decoder,
        deveui: this.item.deveui,
        latitude: this.item.latitude,
        longitude: this.item.longitude,
        name: this.item.name,
        profile: this.item.profile,
        classC: this.item.classC,
        nodeType: this.item.nodeType,
      };

      await this.updateTag({ payload: payload });

      if (this.image !== null) {
        var formdata = new FormData();
        formdata.append("image", this.image);

        this.uploadTagImage({
          payload: formdata,
          tagId: this.item.deveui,
        });
      }
      this.$router.push("/tag");
    },
  },

  async created() {
    this.clearTagData();

    // Get the url parms
    await this.getTags();
    let deveui = this.$route.params.deveui;

    this.tags.forEach((element) => {
      if (element.deveui === deveui) {
        this.item = element;

        if (this.item.mqttMeta) {
          this.mqttMeta = this.item.mqttMeta;
          this.mqttMetaCreate = false;
        } else {
          this.mqttMeta = { brokerId: null, publishTopic: "", topics: [] };
          this.mqttMetaCreate = true;
        }
      }
    });

    if (Object.keys(this.item).length === 0) {
      this.$router.push("/tag");
    }

    await this.getKeysForTag({ tagId: deveui });
    var startDate = new Date();
    startDate.setHours(startDate.getHours() - 24);
  },
};
</script>

<style></style>
